/* Second Header */
.second-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px 110px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo-img {
  height: 50px;
}

/* Logo */
.logo {
  flex: 1;
}

/* Menu Button (hidden on desktop) */
.menu-button {
  display: none; /* Hidden by default */
  background: none;
  border: none;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.menu-button:hover {
  background: linear-gradient(to right, #009e8d, #82b437);
}

/* Menu Items */
.menu-items {
  display: flex;
  gap: 30px;
  margin-right: 30px;
}

/* Menu Item Styles */
.menu-items a {
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.menu-items a:hover {
  color: #00b09b;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Position below the button */
  right: 0; /* Align to the right */
  width: 200px; /* Set a fixed width for the dropdown */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  transform: translateX(100%); /* Start hidden to the right */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Add transition for smooth effect */
  opacity: 0; /* Start hidden */
}

.dropdown-menu.open {
  transform: translateX(0); /* Move into view */
  opacity: 1; /* Make it visible */
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.dropdown-menu li a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  display: block;
}

.dropdown-menu li:hover {
  background-color: #f2f2f2;
}

/* Responsive Styling */

/* Tablet View */
@media (max-width: 1024px) {
  .second-header {
    padding: 20px;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .menu-button {
    display: flex; /* Visible on mobile */
    padding: 3px 8px;
  }

  .menu-items {
    display: none; /* Hide regular menu items on mobile */
  }

  .dropdown-menu {
    width: 200px; /* Set a fixed width for the dropdown */
    display: block; /* Show the dropdown */
  }
}

/* Small Mobile View */
@media (max-width: 480px) {
  .second-header {
    padding: 15px;
  }

  .logo {
    text-align: left;
  }

  .menu-button {
    font-size: 2rem;
  }
}