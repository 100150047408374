.CenteredText-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    width: 100%; /* Changed width to a percentage for responsiveness */
    margin: 0 auto; /* Center horizontally */
    
    text-align: center;
}
  
.Sectionone-heading-text {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #4A4A4A;
  line-height: 1rem;
}

.CenteredText-heading{
  background: linear-gradient(to right, #00b09b, #96c93d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  line-height: 1;
}
  
.CenteredText-paragraph {
    font-size: 16px;
    color: #555;
    width: 80%;
}

/* Responsive styles */
@media (max-width: 768px) {
    .CenteredText-heading {
      font-size: 2rem; /* Slightly smaller font on medium screens */
    }
  
    .CenteredText-paragraph {
      font-size: 14px;
    }
}
  
@media (max-width: 480px) {
    .CenteredText-container {
      /* padding: 15px; */
    }
  
    .CenteredText-heading {
      font-size: 2rem; /* Smaller font for mobile */
    }
  
    .CenteredText-paragraph {
      font-size: 12px;
    }
}
