/* Carousel.module.css */
.carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.carousel-container {
  display: flex;
  transition: transform 0.2s ease-in-out;
}

.carousel-slide {
  min-width: 100%;
}

.carousel img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  display: block;
}

@media (max-width: 768px) { /* Mobile responsiveness */
  .carousel img {
    height: 35vh; /* Increase height for smaller screens if desired */
  }
}

@media (max-width: 480px) { /* Extra small screens */
  .carousel img {
    height: 35vh; /* Adjust as needed for extra small screens */
  }
}


.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
}

.carousel-left-button {
  left: 10px;
}

.carousel-right-button {
  right: 10px;
}

.carousel-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.carousel-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.carousel-active {
  background-color: rgba(0, 0, 0, 1);
}
