.Sectiontwo-contant-section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #083C39; /* Dark green background */
  color: white;
  padding: 0 0 0 20px;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 35px auto;
  flex-wrap: wrap; /* Allow flex items to wrap on small screens */
}
.container-contant-section-2{
  width: 100%;
  background-color: #f4f3ee;
  padding: 20px 0;
}
.Sectiontwo-heading {
  font-size: 35px; /* Reduce title font size */

}
.Sectiontwo-paragraph {
  font-size: 14px; /* Adjust paragraph font size */
  line-height: 1.5;
  padding-bottom: 30px;
}
.Sectiontwo-right-section {
  display: flex;
}

.Sectiontwo-left-section {
  flex: 1;
  padding-right: 200px;
  padding-left: 20px;
}

.Sectiontwo-right-section img {
  width: 100%;
  max-width: 350px;
  border-radius: 15px;
}

.Sectiontwo-buy-now-btn {
  background-color: white;
  color: #083C39;
  border: none;
  padding: 10px 25px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.Sectiontwo-arrow-icon {
  margin-left: 10px;
  font-weight: bold;
}

.Sectiontwo-buy-now-btn:hover {
  background-color: #076c63;
  color: white;
}

/* Media queries for responsiveness */

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .Sectiontwo-contant-section-2 {
    flex-direction: column; /* Stack sections vertically */
    text-align: center;
  }

  .Sectiontwo-left-section {
    padding-right: 0;
  }

  .Sectiontwo-right-section img {
    max-width: 250px; /* Adjust image size for smaller screens */
    margin: 20px 0; /* Add space between sections */
  }

  .Sectiontwo-buy-now-btn {
    font-size: 16px;
    padding: 8px 20px; /* Adjust padding and font size */
  }
}

/* For tablet screens between 768px and 645px height */
@media (max-width: 768px) and (min-height: 645px) {
  .Sectiontwo-contant-section-2 {
    flex-direction: row; /* Maintain the row layout */
    text-align: left; /* Align text to the left */
    

  }

  .Sectiontwo-right-section img {
    max-width: 292px; /* Display the image as in desktop view */
    margin: 0; /* Remove margin */
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .Sectiontwo-contant-section-2 {
    padding: 15px; /* Reduce padding for smaller screens */
    border-radius: 0px;
  }

  .Sectiontwo-buy-now-btn {
    font-size: 14px; /* Further reduce font size */
    padding: 7px 15px;
  }

  .Sectiontwo-right-section img {
    display: none; /* Hide the image on mobile view */
  }

  .Sectiontwo-heading {
    font-size: 24px; /* Reduce title font size */
  }

  .Sectiontwo-paragraph {
    font-size: 14px; /* Adjust paragraph font size */
  }
}




.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header h2 {
  margin: 0;
  font-size: 18px;
}

.close-icon {
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.close-icon:hover {
  color: #000;
}

.popup-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-container input,
.popup-container textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup-container button {
  background: #28a745;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup-container button:hover {
  background: #218838;
}


