/* Main container to hold both text and image */
.Sectionone-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
}

/* Text container for the heading and paragraph */
.Sectionone-text-container {
  flex: 1;
  margin-right: 20px;
}

/* Heading style */
.Sectionone-heading {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #4A4A4A;
}

/* Green gradient for 'Your Heading' words */
.Sectionone-gradient-text {
  background: linear-gradient(to right, #00b09b, #96c93d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

/* Paragraph style */
.Sectionone-paragraph {
  font-size: 1.2rem;
  color: #7f7f7f;
  line-height: 1.5;
}

/* Key Points List */
.Sectionone-key-points {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.Sectionone-list-item {
  font-size: 1.1rem;
  color: #4A4A4A;
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
}

.Sectionone-list-item::before {
  content: '✔';
  color: #96c93d;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2rem;
}

/* Buy Now Button */
.Sectionone-buy-now-btn {
  background-color: #00b09b;
  color: white;
  border: none;
  padding: 10px 70px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.Sectionone-buy-now-btn:hover {
  background-color: #008d7f;
}

/* Image container for the right-side image */
.Sectionone-img-container {
  flex: 0.5;
  display: flex;
  justify-content: right;
  align-items: center;
}

/* Image style */
.Sectionone-image {
  max-width: 100%;
  height: auto;
  max-height: 900px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive styling */

/* For tablets */
@media only screen and (max-width: 768px) {
  .Sectionone-main-container {
    flex-direction: row;
  }

  .Sectionone-text-container {
    margin-right: 20px;
  }

  .Sectionone-img-container {
    flex: 0.6;
  }

  .Sectionone-image {
    max-height: 900px;
  }
}

/* For smaller screens */
@media only screen and (max-width: 480px) {
  .Sectionone-main-container {
    flex-direction: column;
    text-align: left;
  }

  .Sectionone-text-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .Sectionone-image {
    max-width: 100%;
    max-height: 900px;
  }
}
