/* Main container to hold both text and image */
.AboutPage-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
}

/* Text container for the heading and paragraph */
.AboutPage-text-container {
  flex: 1;
  margin-right: 20px;
}

/* Heading style with green gradient */
.AboutPage-heading {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: bold;
  background: linear-gradient(to right, #00b09b, #96c93d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Paragraph style */
.AboutPage-paragraph {
  font-size: 1.2rem;
  color: #7f7f7f;
  line-height: 1.5;
}

/* Image container for the right-side image */
.AboutPage-img-container {
  flex: 0.5;
  display: flex;
  justify-content: right;
  align-items: center;
}

/* Image style */
.AboutPage-image {
  max-width: 100%;
  height: auto;
  max-height: 900px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive styling */

/* For tablets */
@media only screen and (max-width: 768px) {
  .AboutPage-main-container {
      flex-direction: row;
  }

  .AboutPage-text-container {
      margin-right: 20px;
  }

  .AboutPage-img-container {
      flex: 0.6;
  }

  .AboutPage-image {
      max-height: 900px;
  }
}

/* For smaller screens */
@media only screen and (max-width: 480px) {
  .AboutPage-main-container {
      flex-direction: column;
      text-align: left;
  }

  .AboutPage-text-container {
      margin-right: 0;
      margin-bottom: 20px;
  }

  .AboutPage-image {
      max-width: 100%;
      max-height: 900px;
  }
}
