.footer-main{
    background-color: #333;
}
.footer {
    background-color: #333;
    color: white;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
    
}

.footer-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.about-us, .useful-links, .customer-care {
    flex: 1;
    margin: 10px;
}

.footer-h4 {
    font-size: 25px;
    color: #fff;
    margin-bottom: 20px;
}

.footer-ul {
    list-style: none;
    padding: 0;
}

.footer-li {
    margin-bottom: 16px;
}

.footer-a {
    color: #ddd;
    text-decoration: none;
}

.footer-a:hover {
    color: #fff;
    text-decoration: none;
}

.footer-p {
    margin: 0;
    margin-bottom: 16px;
}

.contact-icons {
    margin-top: 10px;
}

.contact-icons a {
    color: #fff;
    margin-right: 15px;
    font-size: 20px;
    text-decoration: none;
}

.contact-icons a:hover {
    color: #4caf50;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
}

.social-icons a {
    color: #fff;
    margin: 0 10px;
    font-size: 20px;
    text-decoration: none;
}

.social-icons a:hover {
    color: #4caf50;
}

/* Media Queries for responsiveness */
@media (max-width: 992px) {
    .footer {
        padding: 20px;
        flex-direction: column;
    }

    .footer-section {
        flex-direction: column;
    }

    .about-us, .useful-links, .customer-care {
        margin: 20px 0;
    }

    .contact-icons a {
        font-size: 18px;
    }

    .social-icons a {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 15px;
    }

    .about-us, .useful-links, .customer-care {
        width: 100%;
        text-align: center;
    }

    .about-us ul, .useful-links ul {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 26px;
        line-height: 2;
    }

    .about-us ul li, .useful-links ul li {
        display: inline-block;
        margin: 0 10px;
    }

    .contact-icons a {
        font-size: 16px;
        margin-right: 10px;
    }

    .social-icons a {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .footer {
        padding: 10px;
    }

    .contact-icons a {
        font-size: 14px;
        margin-right: 5px;
    }

    .social-icons a {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .footer {
      flex-direction: column;
      padding: 0px;
    }
  
    .footer-h4 {
      text-align: left;
      margin-left: 26px;
    }
    .footer-p {
        text-align: left;
        margin-left: 26px;
      }
  }
