/* Header.css */

.app-header {
    background: linear-gradient(to right, #00b09b, #96c93d); /* Green gradient */
    color: white;
    padding: 6px;  /* Reduced padding to shrink the header size */
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Default styles for h1 (for desktop) */
h1 {
    margin: 0;
    font-size: 1.8rem;  
    font-weight: bold;
}

/* Responsive styles for tablets */
@media only screen and (max-width: 1024px) {
    .app-header {
        padding: 5px;  /* Further reduced padding for tablets */
    }

    h1 {
        font-size: 0.5rem;  /* Smaller font size for tablets */
    }
}

/* Responsive styles for mobile */
@media only screen and (max-width: 768px) {
    .app-header {
        padding: 4px;  /* Smaller padding for mobile devices */
    }

    h1 {
        font-size: 1rem;  /* Reduced font size for mobile */
    }
}

/* Responsive styles for small mobile screens */
@media only screen and (max-width: 480px) {
    .app-header {
        padding: 3px;  /* Smallest padding for small screens */
    }

    h1 {
        font-size: 1rem;  /* Smallest font size for small screens */
    }
}
