/* General layout */
.sales-contact-section {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 50px;
  background: url('./../Caruosel/images/image4.jpg') center center/cover no-repeat;
}
.sales-info{
  margin-top: 50px;
}
.sales-contact-info {
  flex: 1;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  text-align: left;
}

.sales-h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.sales-p {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

/* Updated layout for contact info */
.sales-info .sales-p {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start; /* Align items to the start (top) */
}

.sales-icon {
  margin-right: 10px;
  color: #96c93d;
  flex-shrink: 0; /* Prevent the icon from shrinking */
  margin-top: 3px; /* Fine-tune top margin to align with text */
}

/* Style for phone numbers and emails */
.sales-numbers {
  display: flex;
  flex-direction: column; /* Ensure numbers/emails stack vertically */
}

/* Form layout with green background */
.sales-form-container {
  flex: 1;
  padding: 30px;
  background: linear-gradient(to right, #00b09b, #96c93d); /* Green gradient */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  color: #fff;
}

.sales-field {
  margin-bottom: 15px;
}

.sales-field label {
  font-size: 12px;
  color: #fff;
  display: block;
  margin-bottom: 5px;
}

.sales-field input,
.sales-field textarea {
  width: 95%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.sales-field textarea {
  height: 80px;
  width: 95%;
}

.sales-send-btn {
  width: 100%;
  padding: 10px;
  background-color: #27ae60;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.sales-send-btn:hover {
  background-color: #229954;
}

/* Responsive design */
@media (max-width: 768px) {
  .sales-contact-section {
    flex-direction: column;
    padding: 0px;
  }

  .sales-contact-info, .sales-form-container {
    max-width: 100%;
  }
}


.sales-field textarea {
  height: 80px;
  width: 95%;
  resize: none; /* Disable resizing (both horizontal and vertical) */
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}