.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq-heading {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
    color: #333;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    /* border-radius: 5px; */
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 5px 0;
    cursor: pointer;
    color: #333;
  }
  
  .faq-question h1 {
    margin: 0;
    color: #333;
  }
  
  .faq-answer {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .faq-answer p {
    padding: 10px;
    margin: 0;
  }
  
  .open {
    max-height: 300px; /* Adjust according to the content size */
  }
  