/* Main container to hold both text and image */
.Contact-main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 20px;
    max-width: 1000px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  /* Text container for the heading and paragraph */
  .Contact-text-container {
    flex: 1;
    margin-right: 20px;
  }
  
  /* Heading style with green gradient */
  .Contact-heading {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
    background: linear-gradient(to right, #00b09b, #96c93d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Paragraph style */
  .Contact-paragraph {
    font-size: 1.2rem;
    color: #7f7f7f;
    line-height: 1.5;
  }
  
  /* Contact button styles */
  .Contact-contact-buttons {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }
  
  .Contact-call-btn,
  .Contact-mail-btn,
  .Contact-whatsapp-btn {
    background-color: #00b09b;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Contact-call-btn:hover,
  .Contact-mail-btn:hover,
  .Contact-whatsapp-btn:hover {
    background-color: #008d7f;
  }
  
  /* Image container for the right-side image */
  .Contact-img-container {
    flex: 0.5;
    display: flex;
    justify-content: right;
    align-items: center;
  }
  
  /* Image style */
  .Contact-image {
    max-width: 100%;
    height: auto;
    max-height: 900px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive styling */
  
  /* For tablets */
  @media only screen and (max-width: 768px) {
    .Contact-main-container {
      flex-direction: row;
    }
  
    .Contact-text-container {
      margin-right: 20px;
    }
  
    .Contact-img-container {
      flex: 0.6;
    }
  
    .Contact-image {
      max-height: 900px;
    }
  }
  
  /* For smaller screens */
  @media only screen and (max-width: 480px) {
    .Contact-main-container {
      flex-direction: column;
      text-align: left;
    }
  
    .Contact-text-container {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .Contact-image {
      max-width: 100%;
      max-height: 900px;
    }
  }
  /* Contact button styles */
.Contact-contact-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.Contact-call-btn,
.Contact-mail-btn,
.Contact-whatsapp-btn {
  display: flex;
  align-items: center;
  background-color: #00b09b;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Contact-call-btn:hover,
.Contact-mail-btn:hover,
.Contact-whatsapp-btn:hover {
  background-color: #008d7f;
}

.Contact-icon {
  margin-right: 8px;
}